import React from "react";
import { graphql } from "gatsby";
import Img from "gatsby-image";
import Testimonials from "../components/testimonials";
import Layout from "../components/layout";
import { HelmetDatoCms } from "gatsby-source-datocms";
import Hero from "../components/hero";
import FWC from "../images/fwc.png";
import Conservatory from "../images/conservatory.jpeg";
import styled from "styled-components";

const List = styled.ul`
  margin-left: 1.7rem;
  li {
    position: relative;
    &::before {
      content: "";
      width: 10px;
      height: 10px;
      top: 50%;
      transform: translateY(-50%);
      background-color: #103d5e;
      position: absolute;
      left: -25px;
      border-radius: 10px;
    }
  }
`;

const IndexPage = ({ data }) => {
  console.log(data);

  return (
    <Layout>
      <HelmetDatoCms seo={data.page.seoMetaTags} />
      <Hero
        image={data.page.heroImage}
        title={data.page.heading}
        intro={data.page.intro}
      />
      <main className="flex flex-col flex-1">
        <section className="bg-gray-200 py-16">
          <div className="max-w-6xl mx-auto px-4 w-full flex flex-wrap items-center justify-center">
            <div className="flex-none md:flex-1 w-full">
              <h2>About Us</h2>
              <p className="lead">
                With over thirty years of experience, M&amp;J cleaning have
                built an excellent reputation for high-quality, professional
                window cleaning. We are a member of the Federation of Window
                Cleaners - a nationally recognised organisation - assuring that
                your window cleaner works both safely and skillfully.
              </p>
              <p>
                Set up in 1986 by Mark, M&J Cleaning provide window cleaning
                services to both domestic and commercial customers in the Essex
                & London area.
              </p>
            </div>
            <div style={{ textAlign: "center" }}>
              <img className="flex-none p-8" src={FWC} width="300" />
              <p>Membership number 10377</p>
            </div>
          </div>
        </section>
        <section>
          <div className="max-w-6xl px-5 mx-auto px-4 w-full">
            <h2 className="mb-1">Services</h2>
            <p className="mb-6">
              M&J Cleaning will always care for your cleaning requirements in a
              safe and considerate manner. We do not take risks, cut corners or
              damage your property. We offer quality cleaning for a competitive
              price.
            </p>
            <div className="flex flex-wrap -m-5">
              {data.page.services.map((service, index) => (
                <div
                  data-sal="slide-up"
                  data-sal-delay={100 * index + 100}
                  data-sal-easing="ease"
                  data-sal-duration="800"
                  key={service.title}
                  className="m-5 flex-1 shadow rounded"
                  style={{ flexBasis: "200px" }}
                >
                  <Img className="h-40 rounded-t" fluid={service.image.fluid} />
                  <div className="p-4">
                    <h3>{service.title}</h3>
                    <p>{service.description}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </section>
        <section className="bg-gray-200">
          <div className="max-w-6xl px-5 mx-auto px-4 w-full">
            <Testimonials data={data.page.testimonials} />
          </div>
        </section>
        <section
          className="py-12"
          style={{
            backgroundImage: `linear-gradient(45deg, rgba(255,255,255,0.8),rgba(255,255,255,0.8), transparent), url(${Conservatory})`,
            backgroundSize: "cover, cover",
            backgroundPosition: "center, center"
          }}
        >
          <div className="max-w-6xl px-5 mx-auto px-4 w-full flex flex-wrap">
            <div className="flex-none md:flex-1 w-full px-4">
              <h2>Areas Covered</h2>
              <p className="mb-10">
                We offer window cleaning services throughout Essex, London and
                surrounding areas, including:
              </p>
              <List className="mb-12 justify-between">
                <li className="flex-half">North Weald</li>
                <li className="flex-half">Epping</li>
                <li className="flex-half">Harlow</li>
                <li className="flex-half">Loughton</li>
                <li className="flex-half">Buckhurst Hill</li>
                <li className="flex-half">Woodford</li>
                <li className="flex-half">Wanstead</li>
                <li className="flex-half">Ongar</li>
                <li className="flex-half">Fyfield</li>
              </List>
            </div>
            <iframe
              className="flex-1 shadow-lg"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d67954.50868385547!2d0.07524939922714291!3d51.712225093357965!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47d8980c90cc3339%3A0xfe440fc0a49a2d1a!2sEpping!5e0!3m2!1sen!2suk!4v1581594590537!5m2!1sen!2suk"
              width="100%"
              height="450"
              frameBorder="0"
              style={{ border: 0 }}
              allowFullScreen=""
            />
          </div>
        </section>
        <section id="contact" className="bg-gray-400 py-16">
          <div className="max-w-6xl px-5 mx-auto px-4 w-full">
            <h2 className="mb-3">Request a quote</h2>
            <p className="mb-6 text-lg" id="FormContent">
              Fill out the form below and we'll get back to you soon.
            </p>
            <form
              action="/"
              name="Contact"
              method="POST"
              data-netlify="true"
              className="md:w-3/4"
              data-netlify-honeypot="bot-field"
              data-sal="slide-right"
              data-sal-delay="100"
              data-sal-easing="ease"
              data-sal-duration="800"
              onSubmit={() => {
                document.querySelector("#FormContent").innerHTML =
                  "Thanks for getting in touch!";
              }}
            >
              <input type="hidden" name="form-name" value="Contact" />
              <input type="hidden" name="bot-field" />
              <div className="mb-4">
                <label
                  htmlFor="name"
                  className="block text-gray-700 text-sm font-bold mb-2"
                >
                  Your Name
                </label>
                <input
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  type="text"
                  name="Name"
                  id="name"
                  required
                />
              </div>
              <div className="mb-4">
                <label
                  htmlFor="Phone Number"
                  className="block text-gray-700 text-sm font-bold mb-2"
                >
                  Phone Number
                </label>
                <input
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  type="tel"
                  name="Phone Number"
                  id="Phone Number"
                  required
                />
              </div>
              <div className="mb-6">
                <label
                  htmlFor="Postcode"
                  className="block text-gray-700 text-sm font-bold mb-2"
                >
                  Postcode
                </label>
                <input
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  type="text"
                  name="Postcode"
                  id="Postcode"
                />
              </div>
              <button
                className="bg-blue-600 hover:bg-blue-700 text-white font-bold py-2 px-6 rounded focus:outline-none focus:shadow-outline"
                type="submit"
              >
                Submit
              </button>
            </form>
          </div>
        </section>
      </main>
    </Layout>
  );
};

export default IndexPage;

export const query = graphql`
  {
    page: datoCmsHomePage {
      heading
      intro
      heroImage {
        fluid(maxWidth: 2000) {
          ...GatsbyDatoCmsFluid
        }
        alt
      }
      testimonials {
        quote
        author
      }
      services {
        title
        description
        image {
          fluid(imgixParams: { fit: "crop", ar: "5:3", h: "300" }) {
            ...GatsbyDatoCmsFluid
          }
        }
      }
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
    }
  }
`;
