import React from "react";
import Img from "gatsby-image";
import styled from "styled-components";

const Hero = styled.div`
  min-height: 600px;
  max-height: 100vh;
  .content {
    flex: 1 1 50%;
  }
  .gatsby-image-wrapper {
    z-index: 1;
    flex: 1;
    flex-basis: 500px;
    height: 100%;
  }
`;

export default function({ image, title, intro }) {
  return (
    <Hero className="relative flex items-stretch overflow-hidden flex-wrap-reverse">
      <div className="content p-20 z-10 bg-gray-800 inline-block flex items-center">
        <div className="text-center md:text-left">
          <h1
            data-sal="slide-right"
            data-sal-duration="700"
            className="text-white text-2xl  lg:text-3xl font-display mb-6"
          >
            {title}
          </h1>
          <p
            className="mb-12 text-gray-200 text-lg"
            data-sal="slide-right"
            data-sal-duration="700"
          >
            {intro}
          </p>
          <a
            data-sal="slide-right"
            data-sal-delay="300"
            data-sal-duration="700"
            href="#contact"
            className="bg-blue-500 hover:bg-blue-700 text-white py-4 px-6 rounded mb-10"
          >
            Get a Free Quote
          </a>
        </div>
      </div>
      <Img fluid={image.fluid} alt={image.alt} />
    </Hero>
  );
}
