import React, { useState, useEffect } from "react";
import styled from "styled-components";

const Row = styled.div`
  display: flex;
  transform: translateX(-${props => props.activeIndex * 100}%);
  transition: transform ease 0.8s;
  .quote-mark {
    position: absolute;
    font-size: 40rem;
    right: 0;
    top: 50%;
    transform: translateY(-28%);
    color: rgba(10%, 13%, 17%, 20%);
  }
`;

export default function testimonials({ data }) {
  const [activeIndex, setActiveIndex] = useState(0);

  useEffect(() => {
    const id = setTimeout(
      () =>
        setActiveIndex(activeIndex === data.length - 1 ? 0 : activeIndex + 1),
      5000
    );
    return () => clearTimeout(id);
  }, [activeIndex, data.length]);

  return (
    <div className="overflow-hidden py-5">
      <Row activeIndex={activeIndex}>
        {data.map(({ quote, author }, index) => {
          return (
            <div key={quote}>
              <span className="quote-mark font-display">&ldquo;</span>
              <div
                key={quote}
                className={`w-full p-4 pl-8 flex-none rounded flex flex-col justify-center relative border-l-4 border-gray-700`}
              >
                <p className="text-2xl text-blue-600 font-display">{quote}</p>
                <p className=" text-xl">- {author}</p>
              </div>
            </div>
          );
        })}
      </Row>
    </div>
  );
}
